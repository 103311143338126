import { v4 as uuidv4 } from 'uuid';

export class CompartmentsEntity {
    constructor(id, name, numberOfRows, netSurface, remarks, createdDate, surfaceName, aubergineFactor,
        cucumberFactor, pepperFactor, tomatoFactor, updatedDate, createdById, updatedById) {

        this.id = id;
        this.name = name;
        this.numberOfRows = numberOfRows;
        this.netSurface = netSurface;
        this.remarks = remarks;
        this.createdDate = createdDate;
        this.surfaceName = surfaceName;
        this.aubergineFactor = aubergineFactor;
        this.cucumberFactor = cucumberFactor;
        this.pepperFactor = pepperFactor;
        this.tomatoFactor = tomatoFactor;
        this.updatedDate = updatedDate;
        this.createdById = createdById;
        this.updatedById = updatedById;

        this.guid = uuidv4();
    }
    static create() {
        return new CompartmentsEntity(0, '', null, null, '', '', '',
            1.0, 1.0, 1.0, 1.0, '', '', '');
    }

    static createFromObject(compartment) {
        return new CompartmentsEntity(compartment.id, compartment.name, compartment.numberOfRows, compartment.netSurface,
            compartment.remarks, compartment.createdDate, compartment.surfaceName, compartment.aubergineFactor, compartment.cucumberFactor,
            compartment.pepperFactor, compartment.tomatoFactor, compartment.updatedDate, compartment.createdById, compartment.updatedById);
    }
}
