<template>
    <b-card>
        <b-row class="mb-2">
            <b-col class="text-right">
                <b-button @click="showModalCompartment('')" variant="primary">Add compartment</b-button>
                <b-modal id="compartments-modal" ref="modal" :title="compartmentEntity.id > 0 ? 'Edit compartment' :'Add compartment'" size="lg" centered no-close-on-backdrop no-close-on-esc>
                    <ValidationObserver v-slot="{ passes }">
                        <b-form @submit.prevent="passes(saveCompartments)" id="compartments-form">
                            <b-row>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Name" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="compartmentEntity.name" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required" v-slot="{ errors }">
                                        <b-form-group label="Greenhouse" :invalid-feedback="errors[0]" :state="errors[0] ? false : null">
                                            <v-select label="text" v-model="compartmentEntity.surfaceName" :options="['GH1', 'GH2']" placeholder="Select greenhouse"
                                                      :class="{'is-invalid': errors.length > 0}"></v-select>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required|integer|min_value:0" v-slot="{ errors }">
                                        <b-form-group label="Number of rows" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="compartmentEntity.numberOfRows" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required|number|min_value:0" v-slot="{ errors }">
                                        <b-form-group label="Row net surface (m²)" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="compartmentEntity.netSurface" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                        <b-form-group label="Aubergine factor" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="compartmentEntity.aubergineFactor" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                        <b-form-group label="Cucumber factor" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="compartmentEntity.cucumberFactor" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                        <b-form-group label="Pepper factor" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="compartmentEntity.pepperFactor" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                                <b-col md="3" sm="12">
                                    <ValidationProvider rules="required|number|min_value:0.01" v-slot="{ errors }">
                                        <b-form-group label="Tomato factor" :invalid-feedback="errors[0]">
                                            <b-form-input v-model="compartmentEntity.tomatoFactor" :state="errors[0] ? false : null" autocomplete="off"></b-form-input>
                                        </b-form-group>
                                    </ValidationProvider>
                                </b-col>
                            </b-row>
                        </b-form>
                    </ValidationObserver>
                    <template #modal-footer>
                        <div class="text-right">
                            <b-button @click="$bvModal.hide('compartments-modal')" class="mr-2" variant="outline-secondary"> Cancel</b-button>
                            <b-button type="submit" form="compartments-form"  variant="primary"><font-awesome-icon icon="save"></font-awesome-icon> Save</b-button>
                        </div>
                    </template>
                </b-modal>
            </b-col>
        </b-row>

        <b-row class="mb-2">
            <b-col sm="5" md="6" class="my-1">
                <b-input-group size="sm" prepend="Show" append="records">
                    <b-form-select v-model="compartments.itemsPerPage" id="perPageSelect" size="sm" :options="compartments.pageOptions" style="max-width: 5em"></b-form-select>
                </b-input-group>
            </b-col>
<!--            <b-col>-->
<!--                <div class="mt-3 d-flex justify-content-end">-->
<!--                    <b-button-group>-->
<!--                        <b-button :variant="colorAvailable" @click="changeCompartmentStatus('Available')">Vacancy</b-button>-->
<!--                        <b-button :variant="colorOccupied" @click="changeCompartmentStatus('Occupied')">Occupied</b-button>-->
<!--                    </b-button-group>-->
<!--                </div>-->
<!--            </b-col>-->
        </b-row>

        <b-table :items="loadData" :fields="filteredFields" :filter="compartments.filter" :current-page="compartments.currentPage"
                 :per-page="compartments.itemsPerPage" empty-text="No records" striped bordered hover small show-empty :responsive="true"
                 :sort-by="compartments.sortBy" :sort-desc="compartments.sortDesc" ref="compartmentsTable" class="text-left mb-0" @context-changed="tableContextChanged">

            <template #thead-top>
                <b-tr class="table-filters">
                    <b-th v-for="(field, index) in filteredFields" :key="field.key" :class="compartments.filters[index] && compartments.filters[index].value ? 'non-empty' : ''">
                        <b-row v-if="compartments.filters[index]">
                            <b-col v-if="compartments.filters[index].type === 'STRING'">
                                <b-input :placeholder="field.label" v-model="compartments.filters[index].value" debounce="300"></b-input>
                            </b-col>
                            <b-col v-if="compartments.filters[index].inputType === 'Select'">
                                <b-select v-model="compartments.filters[index].value">
                                    <b-select-option value="">ALL</b-select-option>
                                    <b-select-option value="Available">VACANCY</b-select-option>
                                    <b-select-option value="Occupied">OCCUPIED</b-select-option>
                                </b-select>
                            </b-col>
                        </b-row>
                    </b-th>
                </b-tr>
            </template>
            <template #cell(status)="row">
                <p class="text-center" style="font-size: 13.5px;">
                    <b-badge :variant="row.item.status ==='Available' ? 'success' : 'info'">
                        <span v-if="row.item.status === 'Available'">VACANCY</span>
                        <span v-else>{{ row.item.status.toUpperCase() }}</span>
                    </b-badge>
                </p>
            </template>
            <template #cell(name)="row">
                <p>{{row.item.name}}</p>
            </template>

            <template #cell(numberOfRows)="row">
                <p>
                    <span v-b-tooltip.hover :title="'Total rows: ' + row.item.numberOfRows + ' Rows asigned: ' + row.item.rowsAssigned">
                        {{ row.item.rowsAssigned }} / {{ row.item.numberOfRows }}
                    </span>
                </p>
            </template>

            <template #cell(occupancyPercent)="row">
                <p v-if="row.item.occupancyPercent">
                    {{row.item.occupancyPercent}} %
                </p>
                <p v-else>
                    0 %
                </p>
            </template>

            <template #cell(actions)="row">
                <b-button-group size="sm">
                    <b-button size="sm" :to="{ name: 'CompartmentsShow', params: { id: row.item.id } }" v-b-tooltip.hover
                              title="Detail" variant="outline-primary">
                        <font-awesome-icon icon="search"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="showModalCompartment(row.item)" v-b-tooltip.hover title="Edit" variant="outline-primary" v-if="hasPermission">
                        <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                    </b-button>
                    <b-button size="sm" @click="deleteCompartment(row.item.id)" v-b-tooltip.hover title="Delete" variant="outline-primary" v-if="hasPermission">
                        <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                    </b-button>
                </b-button-group>
            </template>

            <template #table-caption>
                <b-row class="no-gutters">
                    <b-col sm="5" md="6">
                        Show {{ start }} to {{ end }} of {{ compartments.totalRows }} record{{compartments.totalRows !== 1 ? 's' : ''}}
                    </b-col>
                    <b-col sm="7" md="6" class="my-1">
                        <b-pagination v-model="compartments.currentPage" :total-rows="compartments.totalRows" :per-page="compartments.itemsPerPage" align="right" class="mb-0"></b-pagination>
                    </b-col>
                </b-row>
            </template>
        </b-table>
    </b-card>
</template>
<script>
import { extend } from 'vee-validate';
import { CompartmentsEntity } from '../../models/compartments';
import { compartmentSaveUrl, compartmentListUrl, compartmentDeleteUrl } from '@routes';
import tableStateMixin from '../../mixins/tableState';
import { required, integer, min_value } from 'vee-validate/dist/rules';
import Utilities from '../../js/utilities';
import $permission from '@/js/permission';

extend('required', {
    ...required,
    message: 'Field required'
});

extend('integer', {
    ...integer,
    message: 'Must be integer'
});

extend('min_value', {
    ...min_value,
    message: 'The value must be greater than 0'
});

extend('number', value => {
    if (Utilities.isNumeric(value)) {
        return true;
    }
    return 'The value must be a number';
});
export default {
    mixins: [tableStateMixin],
    data() {
        return {
            compartmentEntity: CompartmentsEntity.create(),
            toastTitle: 'Compartments',
            compartments: {
                fields: [
                    { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
                    { key: 'name', label: 'Compartment', sortable: true },
                    { key: 'surfaceName', label: 'Greenhouse', sortable: true },
                    { key: 'numberOfRows', label: 'Rows', sortable: true },
                    { key: 'netSurface', label: 'Row net surface (m²)', sortable: true },
                    { key: 'occupancyPercent', label: 'Occupancy rate', sortable: true },
                    { key: 'aubergineFactor', label: 'Aubergine factor', sortable: true, class: 'text-right' },
                    { key: 'cucumberFactor', label: 'Cucumber factor', sortable: true, class: 'text-right' },
                    { key: 'pepperFactor', label: 'Pepper factor', sortable: true, class: 'text-right' },
                    { key: 'tomatoFactor', label: 'Tomato factor', sortable: true, class: 'text-right' },
                    // { key: 'createdBy', label: 'Created by', sortable: true, class: 'text-center' },
                    // { key: 'updatedBy', label: 'Updated by', sortable: true, class: 'text-center' },
                    { key: 'actions', label: 'Actions', class: 'text-center' }
                ],
                fieldsList: [
                    { key: 'status', label: 'Status', sortable: true, class: 'text-center' },
                    { key: 'name', label: 'Compartment', sortable: true },
                    { key: 'surfaceName', label: 'Greenhouse', sortable: true },
                    { key: 'numberOfRows', label: 'Rows', sortable: true },
                    { key: 'netSurface', label: 'Row net surface (m²)', sortable: true },
                    { key: 'occupancyPercent', label: 'Occupancy rate', sortable: true },
                    { key: 'aubergineFactor', label: 'Aubergine factor', sortable: true, class: 'text-right' },
                    { key: 'cucumberFactor', label: 'Cucumber factor', sortable: true, class: 'text-right' },
                    { key: 'pepperFactor', label: 'Pepper factor', sortable: true, class: 'text-right' },
                    { key: 'tomatoFactor', label: 'Tomato factor', sortable: true, class: 'text-right' }
                    // { key: 'createdBy', label: 'Created by', sortable: true, class: 'text-center' },
                    // { key: 'updatedBy', label: 'Updated by', sortable: true, class: 'text-center' }
                ],
                filters: [
                    { column: 'status', type: 'BOOLEAN', value: '', format: '', inputType: 'Select' },
                    { column: 'name', type: 'STRING', value: '', format: '' },
                    { column: 'surfaceName', type: 'STRING', value: '', format: '' }
                ],
                filter: '',
                pageOptions: [10, 25, 50, 75, 100],
                totalRows: 0,
                currentPage: 1,
                itemsPerPage: 25,
                sortBy: 'name',
                sortDesc: false
            },
            breadcrumb: {
                title: 'Compartments',
                path: []
            },
            colorOccupied: 'outline-primary',
            colorAvailable: 'outline-primary',
            colorAll: 'outline-primary',
            compartmentAvailable: true,
            compartmentOccupied: false,
            statusData: [],
            hasPermission: $permission.check('ROLE_COMP_C'),
            hasAssignationPermission: $permission.check('ROLE_COMP_PRAS_L')
        };
    },
    created() {
        this.$emit('update-breadcrumb', this.breadcrumb);
        this.restoreTableState(this.compartments, this.tableState);
        this.changeCompartmentStatus('Available');
    },
    methods: {
        loadData(compartments) {
            if (compartments.sortBy !== '') {
                let data = {
                    filters: this.compartments.filters.filter(filter => filter.value.trim() !== ''),
                    ...compartments
                };
                data.currentPage = (data.currentPage - 1) * data.perPage;
                data.status = this.compartments.status;

                return this.axios.post(compartmentListUrl(), data).then(response => {
                    if (response.data.code === 'OK') {
                        this.compartments.totalRows = response.data.totalRows;
                        return response.data.compartments;
                    } else {
                        return [];
                    }
                }).catch(error => {
                    if (error.response) {
                        this.$bvToast.toast(error.response.data.message, {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else if (error.request) {
                        this.$bvToast.toast('An error occurred while executing the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    } else {
                        this.$bvToast.toast('An error occurred while configuring the request', {
                            title: this.toastTitle,
                            variant: 'danger'
                        });
                    }
                });
            }
        },
        saveCompartments() {
            this.axios.post(compartmentSaveUrl(), this.compartmentEntity).then(response => {
                if (response.data.code === 'OK') {
                    this.$bvModal.hide('compartments-modal');
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'success'
                    });
                    this.$refs.compartmentsTable.refresh();

                } else {
                    this.$bvToast.toast(response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            }).catch(error => {
                if (error.response) {
                    this.$bvToast.toast(error.response.data.message, {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else if (error.request) {
                    this.$bvToast.toast('An error occurred while executing the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                } else {
                    this.$bvToast.toast('An error occurred while configuring the request', {
                        title: this.toastTitle,
                        variant: 'danger'
                    });
                }
            });
        },
        deleteCompartment(compartmentId) {
            this.$swal({
                title: 'Are you sure you want to delete the compartment?',
                icon: 'warning',
                iconColor: '#bf0d0d',
                cancelButtonText: 'Cancel',
                confirmButtonColor: '#bf0d0d',
                confirmButtonText: 'Yes'
            }).then(result => {
                if (result.value) {
                    this.axios.post(compartmentDeleteUrl(), {
                        id: compartmentId
                    }).then(response => {
                        if (response.data.code === 'OK') {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'success'
                            });
                            this.$refs.compartmentsTable.refresh();
                        } else {
                            this.$bvToast.toast(response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    }).catch(error => {
                        if (error.response) {
                            this.$bvToast.toast(error.response.data.message, {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else if (error.request) {
                            this.$bvToast.toast('An error occurred while executing the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        } else {
                            this.$bvToast.toast('An error occurred while configuring the request', {
                                title: this.toastTitle,
                                variant: 'danger'
                            });
                        }
                    });
                }
            });
        },
        showModalCompartment(row) {
            if (row.id > 0) {
                this.compartmentEntity = JSON.parse(JSON.stringify(row));
            } else {
                this.compartmentEntity = CompartmentsEntity.create();
            }

            this.$bvModal.show('compartments-modal');
        },
        changeCompartmentStatus(status) {
            if (status === 'Available') {
                this.compartmentAvailable = !this.compartmentAvailable;
                if (!this.compartmentAvailable) {
                    this.colorAvailable = 'primary';
                } else {
                    this.colorAvailable = 'outline-primary';
                }
            } else if (status === 'Occupied') {
                this.compartmentOccupied = !this.compartmentOccupied;
                if (this.compartmentOccupied) {
                    this.colorOccupied = 'primary';
                } else {
                    this.colorOccupied = 'outline-primary';
                }
            }  else if (status === null) {
                this.colorAll = 'primary';
                this.colorUnassigned = 'outline-primary';
                this.colorAssigned = 'outline-primary';
            }

            let index = this.statusData.indexOf(status);
            if (index >= 0) {
                this.statusData.splice(index, 1);
            } else {
                this.statusData.push(status);
            }

            this.compartments.status = this.statusData.toString();
            this.$nextTick(() => {
                this.$refs.compartmentsTable.refresh();
            });
            this.saveTableState(this.$route.name, this.compartments);
        },
        tableContextChanged(context) {
            this.compartments.sortBy = context.sortBy;
            this.compartments.sortDesc = context.sortDesc;
            this.saveTableState(this.$route.name, this.compartments);
        }
    },
    watch: {
        'compartments.filters': {
            handler() {
                this.$refs.compartmentsTable.refresh();
                this.saveTableState(this.$route.name, this.compartments);
            },
            deep: true
        }
    },
    computed: {
        start() {
            return this.compartments.totalRows === 0 ? 0 : (this.compartments.currentPage - 1) * this.compartments.itemsPerPage + 1;
        },
        end() {
            let offset = (this.compartments.currentPage - 1) * this.compartments.itemsPerPage;

            return (this.compartments.totalRows - offset) < this.compartments.itemsPerPage ? this.compartments.totalRows : offset + this.compartments.itemsPerPage;
        },
        filteredFields() {
            if (this.hasPermission || this.hasAssignationPermission) {
                return this.compartments.fields;
            } else {
                return this.compartments.fieldsList;
            }
        }
    }
};
</script>